<template>
	<div>
		<CRow v-if="hasManageShipmentPermission">
			<CCol class="text-right">
				<CButton
					:disabled="isExporting"
					class="btn-export mb-3"
					color="tertiary"
					data-test-id="btn-export"
					@click="exportShipment"
				>
					{{ isExporting ? "Exporting" : "Export" }}
				</CButton>
			</CCol>
		</CRow>

		<!-- Filter -->
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by shipment ID, order ID, customer name, phone number, tracking number and email"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.status, SHIPMENT_STATUS_OPTIONS)"
									:options="SHIPMENT_STATUS_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Shipment status"
									class="select-custom"
									@input="handleFilterChange('status', $event)"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.shipping_method, shippingMethodStatusOptions)"
									:options="shippingMethodStatusOptions"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Shipping method"
									class="select-custom"
									@input="handleFilterChange('shipping_method', $event)"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="filterShipmentPeriod"
									label="Shipment period"
									placeholder="All periods"
									mode="range"
									@input="handleFilterPeriodChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.courier, courierOptions)"
									:options="courierOptions"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Courier"
									class="select-custom"
									@input="handleFilterChange('courier', $event)"
								/>
							</CCol>
						</CRow>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.branch_id, branchIdOptions)"
									:options="branchIdOptions"
									:allow-empty="false"
									searchable
									label="name"
									track-by="value"
									label-drop-down="Warehouse"
									class="select-custom"
									@input="handleBranchIdChange"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="meta.total"
					append-text="shipment(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<!-- End Filter -->

		<!-- Table -->
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: meta.lastPage,
						activePage: meta.currentPage
					}"
					:fields="SHIPMENT_TABLE_FIELDS"
					:items="dataTable"
					clickable-rows
					link-to="ShipmentDetail"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "shipments" }) }}
							</p>
						</div>
					</template>
					<template #status="{ item = '' }">
						<CBadge class="badge-shipment-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
					<template #order_status="{ item = '' }">
						<CBadge class="badge-order-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
		<!-- End Table -->

		<BaseModalConfirm
			ref="modal-export-shipment"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export report"
			description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportShipment"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import {
	SHIPMENT_TABLE_FIELDS,
	SHIPMENT_COLOR,
	SHIPMENT_TITLE,
	SHIPMENT_STATUS_OPTIONS,
	COURIER_OPTIONS,
	COURIER,
} from '../enums/shipments';
import {
	ORDER_TITLE,
	ORDER_COLOR,
} from '../enums/orders';
import {
	randomSearchString,
	cleanObject,
	cloneDeep,
	hasSearchFilter,
	convertDateRangeToUTC,
} from '../assets/js/helpers';
import { transformedShippingMethodDropdownOption } from '../assets/js/transform/shippings';
import { transformedWarehouseDropdownOption } from '../assets/js/transform/warehouses';
import { postShipmentReportExport } from '../services/api/shipments.api';

export default {
	name: 'Shipments',

	components: {
		BaseModalConfirm,
	},
	data() {
		return {
			SHIPMENT_TABLE_FIELDS,
			SHIPMENT_STATUS_OPTIONS,
			COURIER_OPTIONS,
			COURIER,
			// query params from url
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				status: this.$route.query.status || null,
				shipping_method: Number(this.$route.query.shipping_method) || null,
				courier: this.$route.query.courier || null,
				created_from: this.$route.query.created_from || null,
				created_to: this.$route.query.created_to || null,
				branch_id: this.$route.query.branch_id ?? null,
				r: randomSearchString(),
			},
			isShowFilter: false,
			isExporting: false,
		};
	},

	computed: {
		...mapGetters({
			shippingMethodList: 'shippings/shippingMethodList',
			hasManageShipmentPermission: 'profile/hasManageShipmentPermission',
			warehouseOptions: 'warehouses/warehouseOptions',
		}),
		...mapState('shipments', {
			list: 'list',
		}),
		isLoading() {
			return this.list.isLoading;
		},
		meta() {
			return this.list.meta;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		filterShipmentPeriod() {
			if (!this.queryParams.created_from || !this.queryParams.created_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.created_from),
				end: new Date(this.queryParams.created_to),
			};
		},
		shippingMethodStatusOptions() {
			return [
				{ value: null, name: 'All shipping method' },
				...this.shippingMethodList.map(transformedShippingMethodDropdownOption),
			];
		},
		courierOptions() {
			const allOption = { name: 'All couriers', value: null };
			return [allOption, ...COURIER_OPTIONS];
		},
		branchIdOptions() {
			return [
				{ value: null, name: 'All branch' },
				...this.warehouseOptions.map(transformedWarehouseDropdownOption),
			];
		},
		// mapping for data table
		dataTable() {
			return this.list.data.map((item) => ({
				id: item.id,
				shipment_code: item.code,
				order_code: item.orderCode,
				order_status: {
					title: ORDER_TITLE.ORDER[item.orderStatus],
					color: ORDER_COLOR.ORDER[item.orderStatus],
				},
				status: {
					title: SHIPMENT_TITLE.SHIPMENT[item.status],
					color: SHIPMENT_COLOR.SHIPMENT[item.status],
				},
				created: item.createdAt,
				customer_name: `${item.customerShipment.firstName} ${item.customerShipment.lastName}`,
				phone_number: item.customerShipment.phoneNumber,
				email: item.customerShipment.email,
				shipment_method: item.shipmentMethod.name,
				courier: COURIER[item.courier] || '-',
				tracking_number: item.trackingNumber,
				warehouse_location: item.branch.com7BranchId,
			}));
		},
	},

	async created() {
		this.isShowFilter = this.hasFilter;
		await Promise.all([
			this.fetchData(),
			this.getShippingMethodList(),
			this.getWarehouseList({ per_page: 'all' }),
		]);
	},

	methods: {
		...mapActions({
			getShipments: 'shipments/getShipments',
			getShippingMethodList: 'shippings/getShippingMethodList',
			showToast: 'toast/showToast',
			getWarehouseList: 'warehouses/getWarehouseList',
		}),
		fetchData() {
			this.getShipments(this.queryParams);
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleFilterPeriodChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.created_from = start;
			this.queryParams.created_to = end;
			this.updateUrlParams();
		},
		handleFilterChange(key, event) {
			this.queryParams.page = null;
			this.queryParams[key] = event.value;
			this.updateUrlParams();
		},
		handlePageChange(pageNum = null) {
			this.queryParams.page = pageNum;
			this.updateUrlParams();
		},
		handleFilterCourier(value) {
			this.queryParams.page = null;
			this.queryParams.courier = value;
			this.updateUrlParams();
		},
		handleBranchIdChange({ value = null }) {
			this.queryParams.page = null;
			this.queryParams.branch_id = value;
			this.updateUrlParams();
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				status: null,
				shipping_method: null,
				courier: null,
				created_from: null,
				created_to: null,
				page: null,
				branch_id: null,
				r: null,
			};
			this.updateUrlParams();
		},
		// get selected value, use with BaseDropDown components
		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		exportShipment() {
			this.$refs['modal-export-shipment'].open();
		},
		async handleExportShipment() {
			this.isExporting = true;
			try {
				await postShipmentReportExport({
					...this.queryParams,
					report_key: 'shipment',
				});
				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
				this.$refs['modal-export-shipment'].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	.badge-shipment-status,
	.badge-order-status {
		padding: rem(7) rem(8);
	}

	::v-deep .table-custom {
		.shipment {
			// shipment-cel
			&-cel {
				word-break: unset;

				span {
					margin-right: rem(8);
				}
			}
		}
	}

	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}
</style>
